























import Vue from "vue";

export default Vue.extend({
  name: "PriceTable",
  props: {
    elements: { type: Array, default: () => [] },
  },
  data: () => ({
    sortBy: "date",
    sortDesc: true,
    pagination: {
      sortBy: "date",
    },
    isMobile: false,
    headers: [
      {
        text: "Fecha",
        align: "left",
        value: "date",
      },
      { text: "Valor Cuota",
        align: "right",
        value: "price" 
      },
      { text: "Moneda",
        align: "right",
        value: "currency" 
      },
      { text: "Cuotas en Circulación",
        align: "right",
        value: "volume" 
      },
      { text: "Patrimonio",
        align: "right",
        value: "aum" 
      },
    ],
  }),

  methods: {
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    formatNumber: function( num: number, fixed: number ) { 
      var decimalPart;

      var array = Math.floor(num).toString().split('');
      var index = -3; 
      while ( array.length + index > 0 ) { 
          array.splice( index, 0, '.' );              
          index -= 4;
      }

      if(fixed > 0){
          decimalPart = num.toFixed(fixed).split(".")[1];
          return array.join('') + "," + decimalPart; 
      }
      return array.join(''); 
    },
  },
});
