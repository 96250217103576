

















































import Vue from "vue";
import FileSaver from "file-saver";
import axios, { AxiosResponse, AxiosError } from "axios";
import PriceTable from "./components/PriceTable.vue";
import { VueProcess } from "./declaration/global.d";

declare const process: VueProcess;

const { VUE_APP_API_BASE_URL } = process.env;

export default Vue.extend({
  name: "App",
  data: () => ({
    selection: 0 as number,
    fondo: "" as string,
    valores: [] as Array<string>,
    series: [] as Array<string>,
    run: "" as string,
    periodos: [
      {
        text: "Mes actual",
        value: "mtd"
      },
      {
        text: "Año actual",
        value: "ytd"
      },
      {
        text: "Todo",
        value: "all"
      }
    ],
    periodoSelected: "ytd" as string | ""
  }),
  components: {
    PriceTable
  },
  methods: {
    async selected(): Promise<void> {
      let run: string = this.run;
      let serie = this.series[this.selection];
      let periodo = this.periodoSelected;
      await this.getValoresCuota(run, serie, periodo);
    },
    async getRun(name: string): Promise<void> {
      const path = `${VUE_APP_API_BASE_URL}/run/${encodeURIComponent(name)}`;
      await axios
        .get(path)
        .then((response: AxiosResponse<any>) => (this.run = response.data.run))
        .catch((error: AxiosError) => console.log(error.message));
    },
    async getSeries(run: string): Promise<void> {
      const path = `${VUE_APP_API_BASE_URL}/series/${run}`;
      await axios
        .get(path)
        .then((response: AxiosResponse<any>) => (this.series = response.data))
        .catch((error: AxiosError) => console.log(error.message));
    },
    async getValoresCuota(
      run: string,
      serie: string,
      periodo: string
    ): Promise<void> {
      const path = `${VUE_APP_API_BASE_URL}/prices/${run}/${serie}/${periodo}`;
      await axios
        .get(path)
        .then((response: AxiosResponse<any>) => (this.valores = response.data))
        .catch((error: AxiosError) => console.log(error.message));
    },
    setPeriodo(periodo: any): void {
      let run: string = this.run;
      let serie: string = this.series[this.selection];
      this.getValoresCuota(run, serie, periodo);
    },
    urlToName(url: string): string{
      let url_parent: string = url.replace(/\/$/, "");
      let split_url: Array<string> = url_parent.split("/");
      let largo: number = split_url.length;
      return split_url[largo - 1];
    },
    download(): void {
      const filename =
        this.fondo.replace(/\s/g, "_") +
        "_" +
        this.series[this.selection] +
        ".xlsx";
      let run: string = this.run;
      let serie = this.series[this.selection];
      let periodo = this.periodoSelected;
      const path = `${VUE_APP_API_BASE_URL}/download/${run}/${serie}/${periodo}`;
      axios
        .get(path, { responseType: "blob" })
        .then((response: AxiosResponse<any>) => {
          FileSaver.saveAs(response.data, filename, { autoBom: false });
        })
        .catch((error: AxiosError) => console.log(error.message));
    }
  },
  computed: {
    periodoSelectedCPT: {
      get(): string | null {
        return this.periodoSelected;
      },
      set(value: string): void {
        this.periodoSelected = value;
        this.setPeriodo(value);
      }
    }
  },
  async mounted() {
    this.fondo = this.urlToName(window.location.href);
    const name = this.fondo;
    await this.getRun(name);
    await this.getSeries(this.run);
    await this.selected();
  }
});
